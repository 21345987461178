import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { BaseButton } from '../Button/BaseButton.js';
import { media } from '../utils/styles/media.js';
import { overflowWrap } from '../utils/styles/overflow-wrap.js';

/**
 * Duplicated in _action-button.scss.
 */
const StyledBaseButton = styled(BaseButton).withConfig({
  componentId: "sg16120__sc-1uu23xb-0"
})(["align-items:center;background-color:", ";border:1px solid ", ";border-radius:", ";box-shadow:", ";display:flex;color:", ";gap:", ";justify-content:space-between;padding:", ";text-align:left;text-decoration:none;transition:box-shadow ", ",background-color ", ";width:100%;", "{padding:", ";}& + &{margin-top:", ";}&:hover,&:focus{background-color:", ";color:", ";}&:focus{box-shadow:", ",", ";outline:none;}&:active{color:", ";}", ""], tokens.color.white, tokens.color['neutral-3'], tokens.radius.md, tokens.shadow.default, tokens.color.primary, tokens.space.md, tokens.space.md, tokens.transition.fast, tokens.transition.fast, media.md, props => props.$variant === 'small' ? "".concat(tokens.space.md, " ").concat(tokens.space.lg) : "".concat(tokens.space.lg, " ").concat(tokens.space.xl), tokens.space.lg, tokens.color['neutral-1'], tokens.color['primary-light'], tokens.shadow.focus, tokens.shadow.default, tokens.color['primary-dark'], props => props.$variant === 'small' && css(["box-shadow:none;border-radius:0;border:none;padding:", " ", ";margin:0;"], tokens.space.md, tokens.space.lg));
const StyledActionButtonLabel = styled.span.withConfig({
  componentId: "sg16120__sc-1uu23xb-1"
})(["", " display:flex;flex-direction:column;gap:", ";min-width:0;", ""], overflowWrap, tokens.space.xxs, props => props.$variant === 'small' && css(["gap:0;"]));
const StyledActionButtonLabelHeading = styled.span.withConfig({
  componentId: "sg16120__sc-1uu23xb-2"
})(["display:block;text-decoration:underline;font-size:", ";font-weight:", ";line-height:", ";", ""], tokens['font-size'].xl, tokens['font-weight']['semi-bold'], tokens['line-height'].xs, props => props.$variant === 'small' && css(["font-size:", ";"], tokens['font-size'].md));
const StyledActionButtonLabelDescription = styled.span.withConfig({
  componentId: "sg16120__sc-1uu23xb-3"
})(["color:", ";line-height:", ";text-decoration:none;", ""], tokens.color['neutral-9'], tokens['line-height'].sm, props => props.$variant === 'small' && css(["font-size:", ";"], tokens['font-size'].sm));
const StyledActionButtonIconRightContainer = styled.div.withConfig({
  componentId: "sg16120__sc-1uu23xb-4"
})(["flex:0 0 auto;transition:transform ", ";", ":hover &,", ":focus &{transform:translateX(", ");}"], tokens.transition.medium, StyledBaseButton, StyledBaseButton, tokens.space.xxs);

export { StyledActionButtonIconRightContainer, StyledActionButtonLabel, StyledActionButtonLabelDescription, StyledActionButtonLabelHeading, StyledBaseButton };
